<div class="connector-charging-status" (mouseleave)="showMoreDetails = false">
  <button class="connector-status-icon indicator-blue">
    <svg width="20" height="34" viewBox="0 0 20 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.99155 34H5.99206L7.99155 20.7778H0.99334C-0.76621 20.7778 0.333509 19.3611 0.373499 19.3044C2.95284 14.9978 6.83185 8.57555 12.0105 0H14.01L12.0105 13.2222H19.0287C19.8285 13.2222 20.2684 13.5811 19.8285 14.4689C11.9305 27.4833 7.99155 34 7.99155 34Z" fill="#FCD34D"/>
    </svg>
  </button>

  <div class="connector-status-content">
    <div class="charging-card" (click)="toggleMoreDetails()">
      <div class="logo">
        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Tesla_Motors.svg/558px-Tesla_Motors.svg.png?20220430025811" alt="Tesla Logo" />
      </div>
      <div class="card-content">
        <div class="progress-bar">
          <mat-progress-bar mode="determinate" [value]="50"></mat-progress-bar>
        </div>
        <div class="stats">
          <span class="power">150 kW</span>
          <span class="energy">+5 kWh</span>
          <span class="current">42/42A</span>
          <span class="voltage">118V</span>
        </div>
      </div>
    </div>

    <div class="more-details {{ showMoreDetails ? 'show' : '' }}">
      <h4>Active Connector Status</h4>
      <ul>
        <li>Charging: 20%</li>
        <li>Power Rate: 10.5 KW</li>
        <li>Voltage: 120 V</li>
        <li>Total Energy: 31.22 KWh</li>
        <li>Charge Remaining: 33 mins</li>
        <li>Amount: $2.33</li>
        <li>Amps: 72/72</li>
      </ul>
    </div>
  </div>

</div>
