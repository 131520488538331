import { Component } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@Component({
  selector: 'app-connector-charging-status',
  standalone: true,
  imports: [MatProgressBarModule],
  templateUrl: './connector-charging-status.component.html',
  styleUrl: './connector-charging-status.component.scss'
})
export class ConnectorChargingStatusComponent {

  showMoreDetails = false;

  toggleMoreDetails() {
    this.showMoreDetails = !this.showMoreDetails;
  }

}
