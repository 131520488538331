import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule, MatNavList } from '@angular/material/list';
import { CARD_NAMES } from '@app/pages/account/wallet/wallet.component';
import { AuthService } from '@app/services/auth.service';
import { Wallet } from '@app/services/user.interface';
import { UserService } from '@app/services/user.service';
import { firstValueFrom, of } from 'rxjs';
import { AddWalletDialogComponent } from '../add-wallet-dialog/add-wallet-dialog.component';
import { SimplebarAngularModule } from 'simplebar-angular';

@Component({
  selector: 'app-card-selector-dialog',
  standalone: true,
  imports: [MatNavList, MatListModule, MatIconModule, MatBottomSheetModule, MatButtonModule, CommonModule, SimplebarAngularModule],
  templateUrl: './card-selector-dialog.component.html',
  styleUrl: './card-selector-dialog.component.scss'
})
export class CardSelectorDialogComponent implements OnInit {
  selectedCard = null;
  cards: Wallet[] = [];
  cardNames = CARD_NAMES;
  user = null;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<CardSelectorDialogComponent>,
    private userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.user = await firstValueFrom(this.authService.getUserObject());
    this.loadCards((cards) => {
      this.selectedCard = cards.find((card) => card.isDefault) || this.cards[0];
    });
  }

  loadCards(callback?: (wallets: Wallet[]) => void) {
    return this.userService.getWallets(this.user?.uid).subscribe((wallets) => {
      this.cards = wallets;
      callback?.(wallets);
    });
  }

  selectCard(card: Wallet) {
    this.selectedCard = card;
  }

  closeSheet(): void {
    this.bottomSheetRef.dismiss(this.selectedCard);
  }

  addNewCard() {
    const dialogRef = this.dialog.open(AddWalletDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(() => {
      this.loadCards((wallets) => {
        this.selectedCard = wallets.at(-1);
      });
    });
  }
}
