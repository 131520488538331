import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterLoginComponent } from '@app/components/auth/register-login/register-login.component';
import { AuthService } from '@app/services/auth.service';
import { FirebaseError } from 'firebase/app';
import { catchError, firstValueFrom, of } from 'rxjs';

export const appSchemeUrl = 'gitcharger://gitcharger.com/wallet';

@Component({
  selector: 'app-redirect-wallet',
  standalone: true,
  imports: [],
  templateUrl: './redirect-wallet.component.html',
  styleUrl: './redirect-wallet.component.scss'
})
export class RedirectWalletComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router, private route: ActivatedRoute, private snackBar: MatSnackBar, private dialog: MatDialog) { }

  async ngOnInit() {
    const params: { loginToken?: string } = await firstValueFrom(this.route.queryParams);
    const user = await firstValueFrom(this.authService.getUserObject());

    if (!params.loginToken && !this.authService.isAuthenticated(user?.permission)) {
      this.snackBar.open('Login token is missing.', undefined, { duration: 3000 });
      this.openLoginDialog();
      return;
    }

    if (params.loginToken) {
      await firstValueFrom(this.authService.loginWithToken(params.loginToken)
        .pipe(
          catchError((error: FirebaseError) => {
            console.log(error);
            if (error.code === 'auth/invalid-custom-token') {
              this.snackBar.open('Invalid login token.', undefined, { duration: 3000 });
            } else if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
              this.snackBar.open('Email or password is invalid. Redirecting back...', undefined, { duration: 3000 });
            } else {
              return of(null);
            }
            this.openLoginDialog();
            throw error;
          }),
        ));
    }

    this.redirectToWallet();
  }

  openApp() {
    setTimeout(() => {
      window.location.replace(appSchemeUrl); // Redirect back to the app
    }, 1500);
  }

  openLoginDialog() {
    const dialogRef = this.dialog.open(RegisterLoginComponent, {
      maxWidth: '1000px',
      data: {
        type: 'login',
      },
    });

    dialogRef.afterClosed().subscribe(() => {
      this.authService.getUserObject().subscribe(user => {
        if (this.authService.isAuthenticated(user?.permission)) {
          this.redirectToWallet();
        } else {
          this.openLoginDialog();
        }
      });
    });
  }

  redirectToWallet() {
    this.router.navigate(['/account/wallet'], {
      queryParams: {
        from: 'app-redirect'
      }
    });
  }
}
