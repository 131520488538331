<div class="payment-method-container">
  <h3>Choose payment method</h3>

  <ngx-simplebar [options]="{ autoHide: true }" style="height: 470px">
    <div
      class="card-option"
      [class.selected]="selectedCard?.id === card.id"
      (click)="selectCard(card)"
      *ngFor="let card of cards"
    >
      <img src="/assets/cards/{{ card.cardType }}.svg" alt="Visa" class="card-icon" />
      <div>
        <p class="card-name">{{ card.cardHolderName }}</p>
        <p class="card-number">{{ cardNames[card.cardType] }} ending in {{ card.cardNumber }}</p>
      </div>
      <span class="primary-tag" *ngIf="card.isDefault">Default</span>
      <mat-icon *ngIf="selectedCard?.id === card.id" class="check-icon"
        >check_circle</mat-icon
      >
    </div>
  </ngx-simplebar>

  <!-- Add new card -->
  <button mat-button color="primary" class="add-new-card" (click)="addNewCard()">
    <mat-icon>add</mat-icon> Add new card
  </button>

  <!-- Security Note -->
  <div class="security-info">
    <mat-icon color="primary">check_circle</mat-icon>
    <p>We adhere entirely to the data security standards of the payment card industry.</p>
  </div>

  <!-- Continue Button -->
  <button mat-flat-button color="primary" class="continue-button" (click)="closeSheet()">
    Continue
  </button>
</div>
