<div class="app-user-dropdown">
  @if (user() === undefined) {
  } @else if (!user() || user()?.permission !== 'logged-in') {
    <button (click)="openLoginDialog()" mat-raised-button>
      Login
    </button>
  } @else {
    <div *ngIf="dropdownStyle === 'dropdown'">
      <!-- User Profile Button -->
      <button
        class="user-dropdown-button"
        mat-icon-button
        [matMenuTriggerFor]="userMenu"
      >
        <div class="avatar" [class]="user()?.permission === 'logged-in' ? '' : 'warning'" [style.backgroundColor]="generateLightColor(user().email)">
          <span>{{ user()?.displayName?.charAt(0) }}</span>
          <app-gc-image [src]="getUserPhoto?.(user().uid)" alt="" />
        </div>
      </button>

      <!-- Dropdown Menu -->
      <mat-menu #userMenu="matMenu" class="user-dropdown-menu">
        <!-- User Info -->
        <a [routerLink]="user()?.permission === 'logged-in' ? ['/profile', user().uid] : undefined" mat-menu-item>
          <div class="user-info">
            <div class="avatar" [class]="user()?.permission === 'logged-in' ? '' : 'warning'">
              <mat-icon>person</mat-icon>
              <app-gc-image [src]="getUserPhoto?.(user().uid)" alt="" />
            </div>
            <div class="author">
              <p class="user-name">{{ user().displayName }}</p>
              <a *ngIf="user()?.permission === 'logged-in'" [routerLink]="['/profile', user().uid]">View profile</a>
            </div>
          </div>
        </a>
        <mat-divider></mat-divider>
        @if (user()?.permission === 'logged-in') {
          <a routerLink="/account" mat-menu-item>
            <mat-icon>person</mat-icon>
            <span>My account</span>
          </a>
          <button mat-menu-item [routerLink]="['/profile', user().uid]">
            <mat-icon>emoji_events</mat-icon>
            <span>Rewards</span>
          </button>
          <button mat-menu-item routerLink="/help">
            <mat-icon>info</mat-icon>
            <span>Service help</span>
          </button>
        } @else {
          <button mat-menu-item class="verify-button" (click)="openVerifyDialog()">
            <mat-icon>warning</mat-icon>
            <span>Verify</span>
          </button>
        }
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span>Sign out</span>
        </button>
      </mat-menu>
    </div>

    <mat-nav-list *ngIf="authService.isAuthenticated() && dropdownStyle === 'mat-nav-item'">
      <a
        href="javascript:void(0)"
        mat-list-item
        (click)="toggleNested()"
      >
        <mat-icon matListItemIcon>person</mat-icon>
        <span
          matListItemTitle
          style="display: flex; align-items: center; justify-content: space-between;"
        >
          {{ authService.userSignal().displayName }}
          <mat-icon matListItemIcon>{{ nestedOpen() ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
        </span>
      </a>

      <mat-nav-list *ngIf="nestedOpen()" class="nested-list">
        @if (user()?.permission === 'logged-in') {
          <!-- My Account -->
          <a routerLink="/account" routerLinkActive="activated" mat-list-item>
            <mat-icon matListItemIcon>person</mat-icon>
            <span matListItemTitle>My account</span>
          </a>

          <!-- Rewards -->
          <a mat-list-item [routerLink]="['/profile', user().uid]" routerLinkActive="activated">
            <mat-icon matListItemIcon>emoji_events</mat-icon>
            <span matListItemTitle>Rewards</span>
          </a>
          <a mat-list-item routerLink="/help" routerLinkActive="activated">
            <mat-icon matListItemIcon>info</mat-icon>
            <span matListItemTitle>Service help</span>
          </a>
        } @else {
          <!-- Verify -->
          <a mat-list-item class="verify-button" (click)="openVerifyDialog()">
            <mat-icon matListItemIcon>warning</mat-icon>
            <span matListItemTitle>Verify</span>
          </a>
        }

        <!-- Sign out (Always shown when authenticated) -->
        <a mat-list-item (click)="logout()">
          <mat-icon matListItemIcon>logout</mat-icon>
          <span matListItemTitle>Sign out</span>
        </a>
      </mat-nav-list>
    </mat-nav-list>
  }
</div>
