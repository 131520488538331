import { ApplicationRef, ChangeDetectionStrategy, Component, ComponentFactoryResolver, ComponentRef, Injector, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { LeafletModule } from '@bluehalo/ngx-leaflet';
import { divIcon, marker, Map, SVG, popup } from 'leaflet';
import { firstValueFrom, Subject } from 'rxjs';
import { LeafletConfigService } from '@app/shared/services/leaflet-config.service';
import vectorTileLayer from 'leaflet-vector-tile-layer';
import { featureLayerBase, defaultFeatureLayer } from 'leaflet-vector-tile-layer';
import { environment } from '@environments/environment';
import { AuthService } from '@app/services/auth.service';
import { take } from 'rxjs/operators';
import { Router, RouterOutlet } from '@angular/router';
import {SearchControlComponent} from "@app/pages/map/controls/search-control/search-control.component";
import {MatToolbar} from "@angular/material/toolbar";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserDropdownComponent } from '@app/components/user-dropdown/user-dropdown.component';
import { SearchControlService } from './controls/services/search-control.service';
import { NdewPopupComponent } from '@app/components/ndew-popup/ndew-popup.component';
import { RewardsService } from '@app/services/rewards.service';
import { ConnectorChargingStatusComponent } from '@app/components/charger/connector-charging-status/connector-charging-status.component';

const DEFAULT_ZOOM = 14;

@Component({
  selector: 'app-gc-map',
  standalone: true,
  imports: [LeafletModule, RouterOutlet, SearchControlComponent, MatToolbar, MatButtonModule, MatIconModule, UserDropdownComponent, ConnectorChargingStatusComponent],
  templateUrl: './map.component.html',
  styleUrl: './map.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MapComponent implements OnDestroy, OnInit {
  public map: Map | undefined = undefined;
  public zoom!: number;
  private onDestroy$ = new Subject();
  private userLocation: {
    lat: number;
    lng: number;
  };
  @Input() mapId = 'map-container';
  @ViewChild('jumpToMyLocationButton') jumpToMyLocationButton;

  private mapResizeObserver = new ResizeObserver(() => {
    this.map?.invalidateSize();
  });

  options: any;
  constructor(
    private leafletConfig: LeafletConfigService,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar,
    private searchControlService: SearchControlService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private appRef: ApplicationRef,
    private rewardsService: RewardsService,
  ) {
    this.options = {
      ...this.leafletConfig.defaultMapOptions,
      zoomControl: true,
    };
  }

  ngOnInit(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position) {
          this.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };

          // Set the map to the user's location
          const userLocationMarker = divIcon({
            className: 'user-location-marker',
            html: '<div class="user-location-marker-icon"></div>',
            iconSize: [15, 15],
          })
          marker([this.userLocation.lat, this.userLocation.lng], { icon: userLocationMarker }).addTo(this.map);
          this.jumpToMyLocation();
        } else {
          this.snackBar.open('GitCharger does not have permission to use your location.', undefined, {
            duration: 3000,
          });
        }
      }, () => {
        this.snackBar.open('GitCharger does not have permission to use your location.', undefined, {
          duration: 3000,
        });
      });
    }

    // populate map events
    (async () => {
      const events = await firstValueFrom(this.rewardsService.getEvents());

      events.map((event) => {
        // Create marker
        const eventMarker = marker([event.locationLatitude, event.locationLongitude], {
          icon: this.getDynamicIcon(this.map.getZoom(), {
            text: event.title,
          })
        }).addTo(this.map);

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(NdewPopupComponent);
        const componentRef: ComponentRef<NdewPopupComponent> = componentFactory.create(this.injector);
        componentRef.instance.event = event;

        this.appRef.attachView(componentRef.hostView);

        eventMarker.bindPopup(
          popup({ maxWidth: 320, className: 'leaflet-event-popup' }).setContent((componentRef.hostView as any).rootNodes[0] as HTMLElement)
        );
        eventMarker.on('mouseover', () => {
          eventMarker.openPopup();
        });

        // Update marker size on zoom
        this.map.on('zoom', () => {
          const newZoom = this.map.getZoom();
          eventMarker.setIcon(this.getDynamicIcon(newZoom, {
            text: event.title,
          }));
        });
      });

    })()
  }

  ngOnDestroy(): void {
    try {
      this.map?.clearAllEventListeners();
      this.map?.remove();
      this.mapResizeObserver.disconnect();
      this.onDestroy$.next(true);
      this.onDestroy$.complete();
    } catch {
      // ignore
    }
  }

  // Dynamically calculate icon size based on zoom level
  getDynamicIcon(zoom: number, icon?: {
    text: string;
  }) {
    const size = this.calculateIconSize(zoom); // Define your size calculation logic
    return divIcon({
      // TODO: needs to get the icon from the API or something
      html: `
        <div class="event-marker-container">
          <img src="assets/ndew-logo.svg" style="width: ${size}px; height: ${size}px;" />
          ${icon?.text && `<span class="text" style="font-size: ${size / 2.5}px; width: ${size * 4}px; left: calc(100% + ${size / 6}px);">${icon?.text}</span>`}
        </div>
      `,
      className: 'leaflet-event-marker',
      iconSize: [size, size], // Adjust size dynamically
      iconAnchor: [size / 2, size / 2],
      popupAnchor: [0, -(size / 2)]
    });
  }

  // A function to scale the icon size based on zoom level
  calculateIconSize(zoom: number): number {
    const baseSize = 40; // Smaller base size when zoomed out
    const scaleFactor = 1.5; // Increase size faster as you zoom in
    return baseSize * Math.pow(scaleFactor, (zoom - 13) / 5); // Scaling formula: smaller at lower zoom, larger at higher zoom
  }

  onMapReady(map: Map) {
    this.map = map;
    this.zoom = map.getZoom();
    this.addTileLayerWithToken();
  }

  private addTileLayerWithToken() {
    const tileLayerUrl = `${environment.apiUrl}/tiles/{z}/{x}/{y}`;

    this.authService.getIdToken().pipe(take(1)).subscribe(
      token => {
        if (token) {
          this.addTileLayer(tileLayerUrl, token);
        } else {
          console.error('Token not available');
        }
      },
      error => {
        console.error('Error fetching token', error);
      }
    );
  }

  private featureToLayer(feature, layerName, pxPerExtent, options) {
    // Construct a base feature layer.
    const self = featureLayerBase(feature, layerName, pxPerExtent, options);

    // Compose this feature layer of two sub-layers, one for the visible
    // line controlled by `options` and a second controlled by the path
    // options contained in `options.interaction`. Both will share the same
    // path geometry.
    self.visibleLine = defaultFeatureLayer(
      feature,
      layerName,
      pxPerExtent,
      options
    );
    self.interactionLine = defaultFeatureLayer(
      feature,
      layerName,
      pxPerExtent,
      options.interaction
    );

    // Place the two layers in an SVG group.
    const group = SVG.create("g");
    group.appendChild(self.visibleLine.graphics);
    group.appendChild(self.interactionLine.graphics);
    self.graphics = group;

    // Setting of style is delegated to the sub layers.
    self.setStyle = function setStyle(style) {
      self.visibleLine.setStyle(style);
      self.interactionLine.setStyle(style.interaction);
    };

    // Initial setup of this feature layer.
    self.applyOptions(options);

    return self;
  }

  private iconUrl(count) {
    let iconUrl = 'assets/map/icons/DefaultMarker.svg';
    //TODO based on power level
    if(count == 1) {
      iconUrl = 'assets/map/icons/DefaultMarker.svg'
    } else {
      iconUrl = `data:image/svg+xml;base64,${btoa(this.generateSvgIcon(count))}`;
    }

    return iconUrl;
  }

  private addTileLayer(tileLayerUrl: string, token: string) {
    const stationsLayer = vectorTileLayer(tileLayerUrl, {
      fetchOptions: {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      },
      interactive: true,
      getFeatureId: (feature) => feature.properties.id,
      featureToLayer: this.featureToLayer,
      vectorTileLayerStyles: {
        stations: (properties, zoom) => {
          const count = properties.count;
          // Define default min and max radius values
          let minRadius = 20;
          let maxRadius = 35;

          // Adjust radius based on zoom level
          if (zoom < 8) {
            minRadius = 11;
            maxRadius = 20;
          }

          // Calculate the radius
          let radius = Math.min(maxRadius, minRadius + Math.log(count) * 2);

          // Further adjust radius for count === 1 based on zoom level
          if (count === 1) {
            radius = Math.min(maxRadius, minRadius + zoom / 2);
          }
         // console.log(radius, zoom);

          const iconUrl = this.iconUrl(count);
          //console.log(properties);
          return {
            icon: divIcon({
              iconUrl: iconUrl,
              iconSize: [radius * 2, radius * 2],
              iconAnchor: [radius, radius],
            }),
            interaction: {
              radius,
              fillOpacity: 0,
              stroke: false,
              weight: 2,
              opacity: 1,
            }
          };
        },
      }
    });

    // NOTE: Assign a name to the layer, so we can access this later.
    (stationsLayer as any)._layerName = this.searchControlService.STATION_LAYER_NAME;

   /* stationsLayer.on('mouseover', (e) => {
      const layer = e.layer;
      const latlng = e.latlng || (e.originalEvent && e.originalEvent.latlng);
      if (layer && layer.properties && layer.properties.name && latlng) {
        layer.bindTooltip(layer.properties.name, { permanent: false, className: 'station-tooltip' }).openTooltip(latlng);
      }
    });

    stationsLayer.on('mouseout', (e) => {
      const layer = e.layer;
      if (layer && layer.unbindTooltip) {
        layer.unbindTooltip();
      }
    });*/

    stationsLayer.on('click', (e) => {
      const layer = e.layer;
      const latlng = e.latlng || (e.originalEvent && e.originalEvent.latlng);

      this.map.setView([e?.layer?.properties?.lat, e?.layer?.properties?.lng], DEFAULT_ZOOM);
      if(layer && layer.properties.count > 1) {
        this.map.setZoomAround(latlng, layer.properties.expansionZoom)
      } else {

          //const popupContent = `<strong>${layer.properties.name}</strong><br>Some more info here.`;
          //layer.bindPopup(popupContent).openPopup(latlng);
        this.router.navigate([`/station/${layer.properties.id}`])

      }
    });

    stationsLayer.on('tileerror', (error) => {
      if (error.error && error.error.message.includes('401')) {
        // Token expired or unauthorized, refresh token and retry
        this.authService.getIdToken().pipe(take(1)).subscribe(
          newToken => {
            if (newToken) {
              this.map?.removeLayer(stationsLayer);
              this.addTileLayer(tileLayerUrl, newToken);
            } else {
              console.error('Failed to refresh token');
            }
          },
          tokenError => {
            console.error('Error refreshing token', tokenError);
          }
        );
      }
    });

    this.map?.addLayer(stationsLayer);
  }

  generateSvgIcon(count: number): string {
    const radius = 20;
    const diameter = radius * 2;
    return `
    <svg width="${diameter}" height="${diameter}" viewBox="0 0 ${diameter} ${diameter}" xmlns="http://www.w3.org/2000/svg">
      <circle cx="${radius}" cy="${radius}" r="${radius}" fill="#05BA00" />
      <text x="50%" y="50%" dy=".3em" text-anchor="middle" font-size="12" fill="white">${count}</text>
    </svg>
  `;
  }

  onMapClick() {
    this.searchControlService.removeLocationMarkers();

    if (window.location.pathname.startsWith('/station')) {
      this.router.navigate(['/']);
    }
  }

  isLocationAvailable(): boolean {
    return !!this.userLocation;
  }

  jumpToMyLocation(): void {
    this.map.setView({
      lat: this.userLocation.lat,
      lng: this.userLocation.lng,
    }, DEFAULT_ZOOM);
    this.isMyLocationVisible();
  }

  isMyLocationVisible() {
    const visible = this?.userLocation?.lat === this.map.getCenter().lat && this?.userLocation?.lng === this.map.getCenter().lng && this.map.getZoom() === DEFAULT_ZOOM;
    if (visible) {
      this.jumpToMyLocationButton._elementRef.nativeElement.classList.add('active');
    } else {
      this.jumpToMyLocationButton._elementRef.nativeElement.classList.remove('active');
    }
  }

  onMapMove() {
    this.jumpToMyLocationButton._elementRef.nativeElement.classList.remove('active');
  }
}
